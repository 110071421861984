import { Title } from '@solidjs/meta';
import {
	Breadcrumbs,
	BreadcrumbItem,
	Page,
	Heading,
	TroonAccess,
	Container,
	TextField,
	Label,
	Input,
	Button,
	Link,
} from '@troon/ui';
import { createSignal, Show } from 'solid-js';
import { PictureSection } from '../../../../components/layouts/picture-section';
import { useUser } from '../../../../providers/user';
import type { CombinedError } from '@urql/core';

type Props = {
	error?: CombinedError;
};

export function EnterAccessCode(props: Props) {
	const [code, setCode] = createSignal('');
	const user = useUser();

	return (
		<>
			<Title>Gift Troon Access | Troon</Title>
			<Container class="mt-4">
				<Breadcrumbs>
					<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
					<BreadcrumbItem href="/access/redeem">Redeem</BreadcrumbItem>
				</Breadcrumbs>
			</Container>

			<Container>
				<Page>
					<PictureSection
						src="https://images.ctfassets.net/rdsy7xf5c8dt/3aB6T12TKk56gVwEdh11Gx/656d985e55d0777a2f5b7d78570e6d4d/access-gift-cards.png"
						crop="center"
						mode="contain"
					>
						<Heading as="h1">
							<span class="sr-only">Troon Access</span>
							<TroonAccess class="w-full min-w-48 max-w-64" />
						</Heading>
						<Heading as="p" size="h1">
							The perfect gift for the golfer in your life.
						</Heading>
						<p>
							A Troon Access membership is the ultimate way to play Troon, with year-round savings on tee times at over
							150 world-class courses!
						</p>

						<Show
							when={!user()?.me.troonAccessProductType}
							fallback={
								<div class="rounded border border-neutral bg-neutral-100 p-4">
									Your {user()?.activeTroonCardSubscription?.name} membership is already active!
								</div>
							}
						>
							<div class="flex flex-col gap-4">
								<TextField name="code">
									<Label>Redeem Gift</Label>
									<Input onInput={(e) => setCode(e.currentTarget.value)} />
								</TextField>
								<Show when={props.error}>
									<div aria-live="polite" class="text-red-500">
										Redemption code not found. Please check your code and try again.
									</div>
								</Show>

								<Button disabled={!code()} as={Link} href={`/access/redeem/?code=${code()}`} class="h-fit grow-0">
									Redeem Gift
								</Button>
							</div>
						</Show>
					</PictureSection>
				</Page>
			</Container>
		</>
	);
}

import { createSignal, Show, Suspense } from 'solid-js';
import { createAsync, useSearchParams } from '@solidjs/router';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { useUser } from '../../../providers/user';
import { RedeemWithCode } from './_pages/with-code';
import { EnterAccessCode } from './_pages/enter-code';
import type { CombinedError } from '@urql/core';

export default function RedeemAccessGift() {
	const [params] = useSearchParams<{ code?: string }>();
	const [errors, setErrors] = createSignal<CombinedError>();
	const user = useUser();

	const query = getAccess((err) => {
		setErrors(err);
	});

	const data = createAsync(
		async () => {
			if (!params.code) {
				return;
			}

			return await query({ code: params.code });
		},
		{ deferStream: true },
	);

	return (
		<Suspense>
			<Show when={!user()?.me.troonAccessProductType && data()?.gift} fallback={<EnterAccessCode error={errors()} />}>
				{(gift) => (
					<RedeemWithCode
						// @ts-expect-error
						gift={gift()}
					/>
				)}
			</Show>
		</Suspense>
	);
}

const getAccessQuery = gql(`query getRedemption($code: String!) {
  gift: accessGiftPurchaseByRedemptionCode(redemptionCode: $code) {
    ...Gift
    redemptionCode
    product: troonCardSubscriptionProduct { ...GiftProduct }
  }
}
fragment GiftProduct on TroonCardSubscriptionProduct {
  id
  subscriptionName
  description
  valueProps
  autoRenew
  disclosure
  type
}`);

const getAccess = (onError: (error: CombinedError) => void) =>
	cachedQuery(getAccessQuery, {
		onError,
		retry: { retryIf: () => false },
	});
